<template>
<div>
    <div class="display-block">
        <v-alert v-if="msg" :type="msgType">{{msg}}</v-alert>
        <page-header v-model="search" title="Orders" permission="createOrder" @input="updateApiCall(apiCall,search)" @click="navigateTo('/app/orders/0')"></page-header>
    </div>
    <div>
        <!-- <span v-if="orderCounter?initComponent():''"></span> -->
        <v-data-table :page="currentPage" @update:page="setCurrentPage($event,lsCurrentPageKey)" :items-per-page="items.length"
            :headers="headers" :loading="loading" :items="sortedItems" class="elevation-1" 
            :search="search" hide-default-footer>
            <template v-slot:item.pStatus="{item}">
                <label :class="`${['CREDIT', 'PAID', 'PARTIAL'].indexOf(item.pStatus)>-1 ? item.pStatus: 'CREDIT'}`">{{item.pStatus}}</label>
            </template>
            <template v-slot:item.currentStatus="{item}" v-if="$vuetify.breakpoint.smAndUp">
                <order-status :status="item.currentStatus"></order-status>
            </template>
            <template v-slot:item.date="{item}">
                {{item.date|dateFormat}}
            </template>
            <template v-slot:item.action="{ item }" v-if="$vuetify.breakpoint.smAndUp">
                <!-- <template v-for="icon in icons">
                    <action-button :key="icon.name" :icon="icon.name" :show="isAllowed(icon.permission)" @click="icon.name=='mdi-delete'?deleteOrder(item._id):$router.push(icon.path+''+item._id)"></action-button>
                </template> -->
                <action-button icon="mdi-eye" :show="isAllowed('viewOrder')" @click="$router.push('/app/orders/view/'+item._id)"></action-button>
                <action-button icon="mdi-pencil" v-if="item.currentStatus!=='DELIVERED'" :show="isAllowed('editOrder')" @click="$router.push('/app/orders/'+item._id)"></action-button>
                <action-button icon="mdi-delete" v-if="item.currentStatus!=='DELIVERED'" :show="isAllowed('deleteOrder')" @click="deleteOrder(item._id)"></action-button>
                <!-- <download-button v-if="item.currentStatus=='DELIVERED'" :url="url+'/'+item._id" label="" :downloadFilename="`${item.orderNo}.pdf`"></download-button> -->
            </template>
            <template v-slot:item.total="{item}">
                {{item.total|amountFormat}}
            </template>
            <template v-slot:item.delivery="{item}">
                {{item.delivery.date}}
            </template>
            <template v-slot:item="{item}" v-if="$vuetify.breakpoint.xsOnly">
                <order-card :order="item" :icons="icons" @delete="removeItem"></order-card>
            </template>
        </v-data-table>
        <pagination :api="apiCall" ref="pagination" v-model="items" sort="-createdAt"></pagination>
    </div>
    <v-alert v-if="msg" :type="msgType">{{msg}}</v-alert>
</div>
</template>

<script>
import ActionButton from '@/components/ActionButton'
import appConstants from '@/utils/appConstants'
import OrderCard from '@/components/OrderResponsiveCard'
import DownloadButton from '@/components/DownloadButton'
export default {
    components: {
        ActionButton,
        OrderCard,
        DownloadButton
    },
    data() {
        return {
            search: '',
            headers: [
                { text: 'Order Number', align: 'start', value: 'orderNo', },
                { text: 'Order Date', align: 'start', value: 'date', },
                { text: 'Customer', value: 'customer.displayName' },
                { text: 'Amount', value: 'total', align: 'right' },
                { text: 'Items', value: 'items.length', align: 'right' },
                { text: 'Payment Status', value: 'pStatus', align: 'right' },
                { text: 'Delivery Status', value: 'currentStatus', align: 'right' },
                { text: 'Delivery Date', value:'delivery',align: 'right'},
                { text: "Action", value: "action", align: 'right' },
            ],
            items: [],
            icons: [{
                    name: "mdi-eye",
                    path: "/app/orders/view/",
                    permission: "viewOrder"
                }, {
                    name: "mdi-pencil",
                    path: "/app/orders/",
                    permission: "editOrder"
                },
                {
                    name: "mdi-delete",
                    permission: "deleteOrder"
                }
            ],
            url:appConstants.GENERATE_INVOICE,
            loading: false,
            lsCurrentPageKey:'order-current-page',
            apiCall:appConstants.ORDERS_API
        }
    },
    computed: {
        sortedItems() {
            return this.items.sort((a, b) => (new Date(a.createdAt) < new Date(b.createdAt)) ? 1: (new Date(a.createdAt) > new Date(b.createdAt)) ? -1: 0)
        },
    },
    methods: {
        async deleteOrder(id) {
            try {
                await this.deleteItem("Are you sure you want to delete from Order?", appConstants.ORDERS_API + "/" + id)
                this.items.splice(this.items.findIndex(rec => rec._id == id), 1)
            } catch (error) {
                this.handleError(error)
                this.msg = error.response.data.details
            }
        },
        removeItem(id) {
            this.deleteOrder(id)
        }
    },
}
</script>

<style scoped>
.PAID{
    color: green;
}
.PARTIAL{
    color: orange;
}
.CREDIT{
    color: red;
}
</style>
